import React, { Component } from 'react';
import axios from 'axios';
class BoxView  extends Component {

    componentDidMount() {
        axios.get('http://kancelaria-podatkowa.com/wp-json/wp/v2/pages/46/')
            .then(res => this.setState({
                content: [
                    {
                        id: 1,
                        classicon: 'icon-rounded clipboard',
                        title: res.data.acf.about_title,
                        description: res.data.acf.about_text_1, 
                    },
                    {
                        id: 2,
                        classicon: 'icon-rounded line-chart',
                        title: res.data.acf.title_service_2,
                        description: res.data.acf.about_text_2,
                    },
                    {
                        id: 3,
                        classicon: 'icon-rounded clock',
                        title: res.data.acf.title_service_3,
                        description: res.data.acf.about_text_3,
                    },
                ]
            }))
            .catch(err => console.log(err));
    };

	constructor(props) {
        super(props);
        this.state = {
            imgslide: [
                {
                    id: '1',
                    srcimg: 'images/about/BEA_1.jpg'
                },
                {
                    id: '2',
                    srcimg: 'images/about/BEA_2.jpg'
                },
                {
                    id: '3',
                    srcimg: 'images/about/BEA_3.jpg'
                }
            ],
            content: [
                {
                    id: '1',
                    title: 'Doradca Podatkowy Beata Kuszewska',
                    description: 'Doradca Podatkowy Beata Kuszewska wykonuje zawód doradcy od 1995 roku. Przez okres wieloletniej działalności zdobyła doświadczenie i wypracowała szereg procedur podwyższających i rozwijających jakość i efektywność usług.',
                },
                {
                    id: '2',
                    description: 'Naszą domeną jest zapewnienie Naszym Klientom możliwości skupienia się na tym, co dla Nich najważniejsze - czyli biznesie. My zapewniamy spokój i bezpieczeństwo w zakresie nie tylko finansowo-księgowym ale i podatkowym!!!',
                },
                {
                    id: '3',
                    description: 'Zawiłe i niejasne przepisy, nieustannie zmieniające się prawo, sprawiają, że korzystanie z pomocy doradcy podatkowego jest konieczne już w początkowej fazie prowadzenia działalności gospodarczej. Wsparcie w prawidłowym wykonaniu obowiązków podatkowych, jak i świadczenie usług bieżącego doradztwa na każdym etapie prowadzenia działalności, daje gwarancję uzyskania najbezpieczniejszych rozwiązań.',
                }
            ],
            blogtimeline: [
                // {
                //     id: '1',
                //     year: '2012',
                //     title: 'Start with a small service',
                //     description: 'This was the time when we started our company. We had no idea how far we would go, we weren’t even sure that we would be able to survive for a few years. What drove us to start the company was the understanding that we could provide a service no one else was providing.',
                // },
                // {
                //     id: '2',
                //     year: '2013',
                //     title: 'Website Design, Seo Makerting Online',
                //     description: 'This was the time when we started our company. We had no idea how far we would go, we weren’t even sure that we would be able to survive for a few years. What drove us to start the company was the understanding that we could provide a service no one else was providing.',
                // },
                // {
                //     id: '3',
                //     year: '2014',
                //     title: 'Developer WordPress Themeforest.net',
                //     description: 'This was the time when we started our company. We had no idea how far we would go, we weren’t even sure that we would be able to survive for a few years. What drove us to start the company was the understanding that we could provide a service no one else was providing.',
                // }
            ]
            
        }
    }
    render() {
        return (
            <div>
                <div className="flexslider s2">
                    <div className="flat-slides">                            
                        <ul className="slides">
                            {
                                this.state.imgslide.map(data =>(
                                    <li key={data.id}>   
                                        <img src={data.srcimg} alt="img" />
                                    </li>
                                ))
                            }
                            
                        </ul> 
                    </div>
                </div> 
                    {
                        this.state.content.map(data =>(
                            <div className="box-content" key={data.id} >
                                <div className="title">{data.title}</div>
                                <p>{data.description}</p>
                                <div className="dividers dividers-bc-v3"></div>
                            </div>
                            ))
                    }
                
                    {
                        this.state.blogtimeline.map(data =>(
                            <div className="flat-text-block-timeline" key={data.id} >
                                <div className="year">{data.year}</div>
                                <div className="flat-timeline-content">
                                <div className="box-content">
                                    <div className="title">{data.title}</div>
                                    <p>{data.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
								
        )
                                
    }
}

export default BoxView;