import React, { Component } from 'react';
class MainPost extends Component {
	constructor(props) {
        super(props);
        this.state = {
            tabpost: [
                {
                    id: '1',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '2',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '3',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '4',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '5',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '6',
                    classcategory: 'wrap-one-half services',
                },
            ],
            boxcontent: [
                {
                    id: '1',
                    title: 'Oferujemy prowadzenie ksiąg rachunkowych, podatkowych i innych ewidencji',
                    description: 'W naszej pracy wykorzystujemy nowoczesne rozwiązania technologiczne. Nasi pracownicy systematycznie podnoszą swoje kwalifikacje zawodowe, dzięki czemu świadczą usługi na najwyższym poziomie oraz zawsze zgodnie z najnowszymi zmianami w obowiązujących przepisach, zapewniając bezpieczeństwo naszym klientom. ',
                },
                {
                    id: '2',
                    title: 'W ramach usług księgowych oferujemy:',
                },
            ],
            flatlist: [
                {
                    id: '1',
                    text: 'Opracowanie systemów rachunkowości, w tym polityki rachunkowości'
                },
                {
                    id: '2',
                    text: 'Opracowanie i wdrożenie zasad organizacji działu księgowości, jak i procedur księgowych'
                },
                {
                    id: '3',
                    text: 'Definiowanie najbardziej optymalnego obiegu dokumentów'
                },
                {
                    id: '4',
                    text: 'Ustalenie planu kont'
                },
                {
                    id: '5',
                    text: 'Sporządzanie deklaracji podatkowych, ZUS, sprawozdań statystycznych, raportów dla wewnętrznych jak i zewnętrznych potrzeb Klienta'
                },
                {
                    id: '6',
                    text: 'Sporządzanie sprawozdań finansowych'
                },
                {
                    id: '7',
                    text: 'Prowadzenie księgowości'
                },
                {
                    id: '8',
                    text: 'Nadzór i doradztwo z zakresu prowadzenia księgowości'
                },
            ],
            flattoggle: [
                {
                    id: '1',
                    title: 'Doradca Podatkowy Beata Kuszewska',
                    content: 'Doradca Podatkowy Beata Kuszewska wykonuje zawód doradcy od 1995 roku. Przez okres wieloletniej działalności zdobyła doświadczenie i wypracowała szereg procedur podwyższających i rozwijających jakość i efektywność usług.',
                    classtoggle: 'toggle-title active'
                },
                {
                    id: '2',
                    title: 'Naszą domena',
                    content: 'Naszą domeną jest zapewnienie Naszym Klientom możliwości skupienia się na tym, co dla Nich najważniejsze - czyli biznesie. My zapewniamy spokój i bezpieczeństwo w zakresie nie tylko finansowo-księgowym ale i podatkowym!!!',
                    classtoggle: 'toggle-title'
                },
                {
                    id: '3',
                    title: 'Doradztwo podatkowe',
                    content: 'Doradztwo podatkowe to kompleksowe usługi obejmujące wszystkie aspekty działalności gospodarczej. Po więcej informacji zapraszamy do kontaktu.',
                    classtoggle: 'toggle-title'
                },
            ],
            titleplan: [
                {
                    id: '1',
                    title: 'Outsourcing usług kadrowo-płacowych'
                }
            ],
            textplan: [
                {
                    id: '1',
                    text: 'oferujemy naszym klientom pomoc w podejmowaniu najważniejszych decyzji biznesowych, świadcząc kompleksowe usługi w zakresie:'
                },
                {
                    id: '2',
                    text: 'podatków bezpośrednich (PIT, CIT)'
                },
                {
                    id: '3',
                    text: 'podatków pośrednich (VAT)'
                },
                {
                    id: '4',
                    text: 'postępowań podatkowych'
                }
            ],
            boxcontent2: [
                {
                    id: '1',
                    title: 'Outsourcing usług kadrowo-płacowych',
                    description: 'Powierzenie nam obsługi kadrowo-płacowej zapewnia poufność informacji dotyczącej warunków zatrudnienia oraz wynagrodzeń w ramach Państwa przedsiębiorstwa. Wymiana danych i informacji odbywa się w sposób zabezpieczony przed dostępem osób nieuprawnionych. ',
                },
            ],
            boxcontent3: [
                {
                    id: '1',
                    title: 'W ramach usług kadrowych oferujemy:',
                },
            ],            
            flatlist3: [
                {
                    id: '1',
                    text: 'Prowadzenie dokumentacji pracowniczej zatrudnionych pracowników '
                },
                {
                    id: '2',
                    text: 'Sporządzanie umów o pracę, umów cywilnoprawnych, zmian warunków zatrudnienia oraz dokumentów związanych z zakończeniem zatrudnienia'
                },
                {
                    id: '3',
                    text: 'Udzielanie porad z zakresu podatku dochodowego od osób fizycznych, prawa pracy oraz ubezpieczeń społecznych'
                },
                {
                    id: '4',
                    text: 'Przeglądy kadrowo – płacowe'
                },
                {
                    id: '5',
                    text: 'Przygotowywanie, opiniowanie umów o pracę'
                },
                {
                    id: '6',
                    text: 'Przygotowywanie Regulaminów Pracy oraz Regulaminów Wynagradzania'
                },
            ],
            boxcontent4: [
                {
                    id: '1',
                    title: 'W ramach usług płacowych oferujemy:',
                },
            ],            
            flatlist4: [
                {
                    id: '1',
                    text: 'kalkulację wynagrodzeń i sporządzanie list płac'
                },
                {
                    id: '2',
                    text: 'Rozliczanie umów o dzieło oraz umów zlecenia'
                },
                {
                    id: '3',
                    text: 'obliczanie zobowiązań publiczno-prawnych oraz sporządzanie deklaracji ZUS oraz PIT'
                },
                {
                    id: '4',
                    text: 'reprezentowanie klienta przed ZUS i organami skarbowymi'
                },
                {
                    id: '5',
                    text: 'sporządzanie informacji i deklaracji rocznych w zakresie PIT i IFT'
                },
                {
                    id: '6',
                    text: 'obsługę PFRON oraz ZFŚS'
                },
            ],
        }
    }
    render() {
        return (
            <div className="col-md-9 content-tab">
                {
                    this.state.tabpost.map(data =>(
                        <div className="content-inner" key={data.id} >
                            <div className={data.classcategory}>
                                <div className="one-half v1">
                                    <div className="box-content">
                                        {
                                            this.state.boxcontent.map(data =>(
                                                <div key={data.id} >
                                                   <div className="title">{data.title}</div>
                                                    <p>{data.description}</p>
                                                    <div className="dividers dividers-bc-v1"></div> 
                                                </div>
                                            ))
                                        }
                                        <ul className="flat-list">
                                            {
                                                this.state.flatlist.map(data=> (
                                                    <li key={data.id} >{data.text} </li>
                                                ))
                                            }
                                        </ul>
                                    </div>									
                                </div>
                                <div className="one-half v2">
                                    <div className="flexslider s1">
                                        <div className="flat-slides">                            
                                            <ul className="slides">
                                                <li>   
                                                    <img src="images/slides/sv1.jpg" alt="img" />
                                                </li>
                                                
                                                <li>
                                                    <img src="images/slides/sv2.jpg" alt="img" />
                                                </li>
                                                
                                                <li>
                                                    <img src="images/slides/sv3.jpg" alt="img" />
                                                </li>
                                            </ul> 
                                        </div>
                                    </div>

                                    <div className="flat-accordion style">
                                        {
                                            this.state.flattoggle.map(data =>(
                                                <div className="flat-toggle" key={data.id} >
                                                    <h6 className={data.classtoggle}>{data.title}</h6>
                                                    <div className="toggle-content">
                                                        <p>{data.content}</p>                               
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="dividers dividers-bc-v2"></div>
                                <div className="box-content">
                                    {
                                        this.state.boxcontent2.map(data =>(
                                            <div key={data.id} className="title">{data.title}</div>
                                        ))
                                    }
                                    {
                                        this.state.boxcontent2.map(data =>(
                                            <div key={data.id} >
                                                <p>{data.description}</p>
                                                <div className="dividers dividers-bc-v3"></div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="dividers dividers-bc-v2"></div>
                                <div className="box-content">
                                        {
                                            this.state.boxcontent3.map(data =>(
                                                <div key={data.id} >
                                                   <div className="title">{data.title}</div>
                                                    <p>{data.description}</p>
                                                    <div className="dividers dividers-bc-v1"></div> 
                                                </div>
                                            ))
                                        }
                                        <ul className="flat-list">
                                            {
                                                this.state.flatlist3.map(data=> (
                                                    <li key={data.id} >{data.text} </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="dividers dividers-bc-v2"></div>
                                    <div className="box-content">
                                        {
                                            this.state.boxcontent4.map(data =>(
                                                <div key={data.id} >
                                                   <div className="title">{data.title}</div>
                                                    <p>{data.description}</p>
                                                    <div className="dividers dividers-bc-v1"></div> 
                                                </div>
                                            ))
                                        }
                                        <ul className="flat-list">
                                            {
                                                this.state.flatlist4.map(data=> (
                                                    <li key={data.id} >{data.text} </li>
                                                ))
                                            }
                                        </ul>
                                    </div>				
                            </div>
						</div>

                    ))
                }
                
            </div>
        )
                                
    }
}

export default MainPost;