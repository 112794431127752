import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class BottomBar extends Component {
    render() {
        return (
            <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright">
                                    <p>Copyright 2022 BEA. Developed by Piotr Cembrowski</p>
                                </div>
                                <ul className="menu-footer">
                                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Home</Link></li>
                                    <li><Link to="/about-v1" onClick={() => {window.location.href="/about-v1"}} title="">O Nas</Link></li>
                                    <li><Link to="/contact-v1" onClick={() => {window.location.href="/contact-v1"}} title="">Kontakt</Link></li>
                                </ul>
                                {/* <!-- /.menu-footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
                /* <!-- /.footer-bottom --> */
        );
    }
}

export default BottomBar;