import React, { Component } from 'react';
import axios from 'axios';
class MainPost extends Component {

    componentDidMount() {
        axios.get('http://kancelaria-podatkowa.com/wp-json/wp/v2/pages/27/')
            .then(res => this.setState({
                boxcontent: [
                    {
                        id: '1',
                        title: res.data.acf.servicec2_title_1,
                        description: res.data.acf.servicec2_text_1,
                    },
                    {
                        id: '2',
                        title: res.data.acf.servicec2_title_2,
                    },
                ],
                flatlist: [
                    {
                        id: '1',
                        text: res.data.acf.service2_bullet_1
                    },
                    {
                        id: '2',
                        text: res.data.acf.service2_bullet_2
                    },
                    {
                        id: '3',
                        text: res.data.acf.service2_bullet_3
                    },
                    {
                        id: '4',
                        text: res.data.acf.service2_bullet_4
                    },
                    {
                        id: '5',
                        text: res.data.acf.service2_bullet_5
                    },
                    {
                        id: '6',
                        text: res.data.acf.service2_bullet_6
                    },
                    {
                        id: '7',
                        text: res.data.acf.service2_bullet_7
                    },
                    {
                        id: '8',
                        text: res.data.acf.service2_bullet_8
                    },
                    {
                        id: '9',
                        text: res.data.acf.service2_bullet_9
                    },
                ],
                flattoggle: [
                    {
                        id: '1',
                        title: res.data.acf.service2_sidebox_title_1,
                        content: res.data.acf.service2_sidebox_text_1,
                        classtoggle: 'toggle-title active'
                    },
                    {
                        id: '2',
                        title: res.data.acf.service2_sidebox_title_2,
                        content: res.data.acf.service2_sidebox_text_2,
                        classtoggle: 'toggle-title'
                    },
                    {
                        id: '3',
                        title: res.data.acf.service2_sidebox_title_3,
                        content: res.data.acf.service2_sidebox_text_3,
                        classtoggle: 'toggle-title'
                    },
                ],
                titleplan: [
                    {
                        id: '1',
                        title: res.data.acf.servicec2_title_3
                    }
                ],
                textplan: [
                    {
                        id: '1',
                        text: res.data.acf.servicec2_text_3
                    },
                    {
                        id: '2',
                        text: res.data.acf.servicec2_text_4
                    },
                    {
                        id: '3',
                        text: res.data.acf.servicec2_text_5
                    },
                    {
                        id: '4',
                        text: res.data.acf.servicec2_text_6
                    }
                ]
            }))
            .catch(err => console.log(err));
    };

	constructor(props) {
        super(props);
        this.state = {
            tabpost: [
                {
                    id: '1',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '2',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '3',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '4',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '5',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '6',
                    classcategory: 'wrap-one-half services',
                },
            ],
            boxcontent: [
                {
                    id: '1',
                    title: 'Doradztwo Podatkowe',
                    description: 'Doradztwo podatkowe to kompleksowe usługi obejmujące wszystkie aspekty działalności gospodarczej. Beata Kuszewska przez lata stworzyła zespół zaangażowany w swoją prace i skupiony w swojej działalności przede wszystkim na budowie trwałej relacji z klientem, świadcząc usługi wykraczające nawet ponad jego wymagania, co jest misją firmy.',
                },
                {
                    id: '2',
                    title: 'W ramach doradztwa podatkowego oferujemy :',
                },
            ],
            flatlist: [
                {
                    id: '1',
                    text: 'Przeglądy podatkowe w tym przyjętych procedur podatkowych klienta'
                },
                {
                    id: '2',
                    text: 'Analizy podatkowe'
                },
                {
                    id: '3',
                    text: 'Doradztwo podatkowe w zakresie kosztów wynagradzania pracowników, kadr zarządzających.'
                },
                {
                    id: '4',
                    text: 'Reprezentowanie klientów przed organami podatkowymi, urzędami skarbowymi oraz w postępowaniach przed Naczelnym Sądem Administracyjnym'
                },
                {
                    id: '5',
                    text: 'Interpretacje oraz wyjaśnienia obowiązujących przepisów podatkowych'
                },
                {
                    id: '6',
                    text: 'Planowanie podatkowe'
                },
                {
                    id: '7',
                    text: 'Wydawanie opinii zwianych z prowadzoną działalnością od strony księgowej, podatkowej oraz prawnej'
                },
                {
                    id: '8',
                    text: 'Nadzorowanie funkcji księgowo – finansowych Klienta;'
                },
                {
                    id: '9',
                    text: 'Przeglądy księgowe – dotyczące zarówno przeglądu organizacji pracy działu księgowego, dokumentów, jak i przeprowadzanych transakcji.'
                },
            ],
            flattoggle: [
                {
                    id: '1',
                    title: 'Doradca Podatkowy Beata Kuszewska',
                    content: 'Doradca Podatkowy Beata Kuszewska wykonuje zawód doradcy od 1995 roku. Przez okres wieloletniej działalności zdobyła doświadczenie i wypracowała szereg procedur podwyższających i rozwijających jakość i efektywność usług.',
                    classtoggle: 'toggle-title active'
                },
                {
                    id: '2',
                    title: 'Naszą domena',
                    content: 'Naszą domeną jest zapewnienie Naszym Klientom możliwości skupienia się na tym, co dla Nich najważniejsze - czyli biznesie. My zapewniamy spokój i bezpieczeństwo w zakresie nie tylko finansowo-księgowym ale i podatkowym!!!',
                    classtoggle: 'toggle-title'
                },
                {
                    id: '3',
                    title: 'Doradztwo podatkowe',
                    content: 'Doradztwo podatkowe to kompleksowe usługi obejmujące wszystkie aspekty działalności gospodarczej. Po więcej informacji zapraszamy do kontaktu.',
                    classtoggle: 'toggle-title'
                },
            ],
            titleplan: [
                {
                    id: '1',
                    title: 'W ramach outsourcingu usług księgowych'
                }
            ],
            textplan: [
                {
                    id: '1',
                    text: 'oferujemy naszym klientom pomoc w podejmowaniu najważniejszych decyzji biznesowych, świadcząc kompleksowe usługi w zakresie:'
                },
                {
                    id: '2',
                    text: 'podatków bezpośrednich (PIT, CIT)'
                },
                {
                    id: '3',
                    text: 'podatków pośrednich (VAT)'
                },
                {
                    id: '4',
                    text: 'postępowań podatkowych'
                }
            ]
            
        }
    }
    render() {
        return (
            <div className="col-md-9 content-tab">
                {
                    this.state.tabpost.map(data =>(
                        <div className="content-inner" key={data.id} >
                            <div className={data.classcategory}>
                                <div className="one-half v1">
                                    <div className="box-content">
                                        {
                                            this.state.boxcontent.map(data =>(
                                                <div key={data.id} >
                                                   <div className="title">{data.title}</div>
                                                    <p>{data.description}</p>
                                                    <div className="dividers dividers-bc-v1"></div> 
                                                </div>
                                            ))
                                        }
                                        <ul className="flat-list">
                                            {
                                                this.state.flatlist.map(data=> (
                                                    <li key={data.id} >{data.text} </li>
                                                ))
                                            }
                                        </ul>
                                    </div>									
                                </div>
                                <div className="one-half v2">
                                    <div className="flexslider s1">
                                        <div className="flat-slides">                            
                                            <ul className="slides">
                                                <li>   
                                                    <img src="images/slides/sv1.jpg" alt="img" />
                                                </li>
                                                
                                                <li>
                                                    <img src="images/slides/sv2.jpg" alt="img" />
                                                </li>
                                                
                                                <li>
                                                    <img src="images/slides/sv3.jpg" alt="img" />
                                                </li>
                                            </ul> 
                                        </div>
                                    </div>

                                    <div className="flat-accordion style">
                                        {
                                            this.state.flattoggle.map(data =>(
                                                <div className="flat-toggle" key={data.id} >
                                                    <h6 className={data.classtoggle}>{data.title}</h6>
                                                    <div className="toggle-content">
                                                        <p>{data.content}</p>                               
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="dividers dividers-bc-v2"></div>
                                <div className="box-content">
                                    {
                                        this.state.titleplan.map(data =>(
                                            <div key={data.id} className="title">{data.title}</div>
                                        ))
                                    }
                                    {
                                        this.state.textplan.map(data =>(
                                            <div key={data.id} >
                                                <p>{data.text}</p>
                                                <div className="dividers dividers-bc-v3"></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
						</div>

                    ))
                }
                
            </div>
        )
                                
    }
}

export default MainPost;