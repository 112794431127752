const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/',
    },
    {
        id: 2,
        name: 'O nas',
        linkmenu: '/about-v3',
    },
    {
        id: 3,
        name: 'Usługi',
        linkmenu: '/services-v1',
        namesub:  [
            {
                id: 1,
                sub: 'Usługi Księgowe',
                links: '/services-v1',
                submenu: undefined
            },
            {
                id: 2,
                sub: 'Doradztwo Podatkowe',
                links: '/services-v2',
                submenu: undefined
            }
        ],
    },
    {
        id: 6,
        name: 'Kontakt',
        linkmenu: '/contact-v1',
    }
]

export default menus;