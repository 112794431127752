import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <p>Doradca Podatkowy Beata Kuszewska wykonuje zawód doradcy od 1995 roku. Przez okres wieloletniej działalności zdobyła doświadczenie i wypracowała szereg procedur podwyższających i rozwijających jakość i efektywność usług.
                </p>
                <p>Naszą domeną jest zapewnienie Naszym Klientom możliwości skupienia się na tym, co dla Nich najważniejsze - czyli biznesie.
                </p>	
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;