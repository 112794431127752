import React, { Component } from 'react';
import axios from 'axios';
import { Header, TopBar , Footer,  Loader } from '../layouts/general';
import { Link } from "react-router-dom";

class Contact01 extends Component {
    componentDidMount() {
        axios.get('http://kancelaria-podatkowa.com/wp-json/wp/v2/pages/36/')
            .then(res => this.setState({
                headers: [
                    {
                        id: 1,
                        names: 'Kontakt',
                    }
                ],
                titleheading: [
                    {
                        id: '1',
                        title: 'Kontakt'
                    }
                ],
                breadcrumbs: [
                    {
                        id: 1,
                        title: 'Home',
                        classicon: 'fa fa-angle-right',
                        aria: 'true'
                    },
                    {
                        id: 2,
                        title: 'Kontakt',
                        classicon: '',
                        aria: ''
                    }
                ],
                contactinfo: [
                    {
                        id: '1',
                        title: res.data.acf.kontakt_left_kolumn_title_1,
                    },
                    {
                        id: '2',
                        title: 'Adres',
                        info: res.data.acf.kontakt_left_kolumn_adres
                    },
                    {
                        id: '3',
                        title: 'Telefon',
                        info: res.data.acf.kontakt_left_kolumn_telefon
                    },
                    {
                        id: '4',
                        title: 'E-mail',
                        info: res.data.acf.kontakt_left_kolumn_mail
                    },
                    {
                        id: '5',
                        title: 'www',
                        info: res.data.acf.kontakt_left_kolumn_www
                    }
                ],
                contactinfo2: [
                    {
                        id: '1',
                        title: res.data.acf.kontakt_middle_kolumn_title_1,
                    },
                    {
                        id: '2',
                        title: 'Adres',
                        info: res.data.acf.kontakt_middle_kolumn_adres
                    },
                    {
                        id: '3',
                        title: 'Telefon',
                        info: res.data.acf.kontakt_middle_kolumn_telefon
                    },
                    {
                        id: '4',
                        title: 'E-mail',
                        info: res.data.acf.kontakt_middle_kolumn_mail
                    },
                    {
                        id: '3',
                        title: 'www',
                        info: res.data.acf.kontakt_middle_kolumn_www
                    },
                ],
                contactinfo3: [
                    {
                        id: '1',
                        title: res.data.acf.kontakt_right_kolumn_title_1,
                        info: res.data.acf.kontakt_right_kolumn_nr
                    },
                    {
                        id: '2',
                        title: 'Licencja Ministra Finansów Usługowe Prowadzenie Ksiąg',
                        info: res.data.acf.kontakt_right_kolumn_nr_ksiag
                    },
                    {
                        id: '3',
                        title: 'Telefon',
                        info: res.data.acf.kontakt_right_kolumn_telefon
                    },
                    {
                        id: '4',
                        title: 'E-mail',
                        info: res.data.acf.kontakt_right_kolumn_mail
                    }
                ]
            }))
            .catch(err => console.log(err));
    };

    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Blog',
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Kontakt'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Kontakt',
                    classicon: '',
                    aria: ''
                }
            ],
            contactinfo: [
                {
                    id: '1',
                    title: 'Biuro Rachunkowe BEA Doradztwo Podatkowe Beata Kuszewska',
                },
                {
                    id: '2',
                    title: 'Adres',
                    info:'ul. Bukowińska 24 a lok. 67, 02-703 Warszawa'
                },
                {
                    id: '3',
                    title: 'Telefon',
                    info:'Zadzwoń: +48 22 423-81-51, +48 22 843-24-62'
                },
                {
                    id: '4',
                    title: 'E-mail',
                    info:'biuro@doradztwo-podatkowe.com'
                },
                {
                    id: '5',
                    title: 'www',
                    info:'www.doradztwo-podatkowe.com'
                }
            ],
            contactinfo2: [
                {
                    id: '1',
                    title: 'Kancelaria Podatkowa Beata Kuszewska i Wspólnicy Sp. z o.o.',
                },
                {
                    id: '2',
                    title: 'Adres',
                    info:'ul. Bukowińska 24 a lok. 67, 02-703 Warszawa'
                },
                {
                    id: '3',
                    title: 'Telefon',
                    info:'Zadzwoń: +48 22 423-81-51, +48 22 416-60-36'
                },
                {
                    id: '4',
                    title: 'E-mail',
                    info:'biuro@kancelaria-podatkowa.com'
                },
                {
                    id: '3',
                    title: 'www',
                    info:'www.kancelaria-podatkowa.com'
                },
            ],
            contactinfo3: [
                {
                    id: '1',
                    title: 'Doradca Podatkowy Beata Kuszewska',
                    info:'nr wpisu 04368'
                },
                {
                    id: '2',
                    title: 'Licencja Ministra Finansów Usługowe Prowadzenie Ksiąg',
                    info:'nr wpisu 17663/00'
                },
                {
                    id: '3',
                    title: 'Telefon',
                    info:'Zadzwoń: +48 661-94-24-89'
                },
                {
                    id: '4',
                    title: 'E-mail',
                    info:'beata.kuszewska@doradztwo-podatkowe.com'
                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        {
                                            this.state.titleheading.map(data =>(
                                                <h1 key={data.id} className="h1-title">{data.title}</h1>
                                            ))
                                        }       
                                    </div>
                                    <ul className="breadcrumbs">
                                        {
                                            this.state.breadcrumbs.map(data =>(
                                                <li key={data.id} ><Link to="#" title="">{data.title}<i className={data.classicon} aria-hidden={data.aria}></i></Link></li>
                                            ))
                                        }
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="flat-row pd-contact-v1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div style={{height: "100px"}}>
                                    <img src="images/logo_BEA.png" />
                                    </div>
                                    <div className="contact-info">
                                        {
                                            this.state.contactinfo.map(data =>(
                                                <div className="info info-address" key={data.id} >
                                                    <div className="title">{data.title}</div>
                                                    <p>{data.info}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div> 
                                <div className="col-md-4">
                                <div style={{height: "100px"}} className={'logo_kontakt_container'}>
                                    <img src="images/Logo_KID_kontakt.png" />
                                    </div>
                                    <div className="contact-info">
                                        {
                                            this.state.contactinfo2.map(data =>(
                                                <div className="info info-address" key={data.id} >
                                                    <div className="title">{data.title}</div>
                                                    <p>{data.info}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div> 
                                <div className="col-md-4">
                                <div style={{height: "100px"}}>
                                    <img src="images/logo-blog.png" />
                                    </div>
                                    <div className="contact-info">
                                        {
                                            this.state.contactinfo3.map(data =>(
                                                <div className="info info-address" key={data.id} >
                                                    <div className="title">{data.title}</div>
                                                    <p>{data.info}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div> 


                            </div>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="flat-form-info">
                                        <form id="contactform"  method="post" action="./contact/contact-process.php" noValidate="novalidate" className="form-info">
                                            <div className="one-half v3">
                                                <p className="input-info"><input type="text" name="name" id="name"  placeholder="Imię" required="required" /></p>
                                                <p className="input-info"><input type="email" name="email" id="email"  placeholder="Email" required="required" /></p>
                                                <p className="input-info"><input type="text" name="subject" id="subject"  placeholder="Temat" required="required" /></p>
                                                <p className="input-info"><button type="submit" className="submit">Wyślij Wiadomość</button></p>
                                            </div>
                                            <div className="one-half v4">
                                                <p className="input-info"><textarea id="message-contact" name="message" placeholder="Wiadomość..." required="required"></textarea></p>
                                            </div>
                                        </form>
                                    </div>
                                </div> 

                            </div>
                        </div>
			        </section>
            <div>

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2446.381326822732!2d21.026399!3d52.181941!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x36bd38d62d314601!2sBiuro%20Rachunkowe%20BEA%20Doradztwo%20Podatkowe%20Beata%20Kuszewska!5e0!3m2!1spl!2spl!4v1655573272536!5m2!1spl!2spl"
                    width="100%"
                    height="450"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                ></iframe>
            </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Contact01;