import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class UsefulLink extends Component {
    render() {
        return (
            <div className="widget widget-services">
                <ul className="one-half first">
                    <li><Link to="/about-v3" onClick={() => {window.location.href="/build/about-v3"}} title="">O Nas</Link></li>
                    <li><Link to="/services-v1" onClick={() => {window.location.href="/build/services-v1"}} title="">Usługi Księgowe</Link></li>
                    <li><Link to="/services-v2" onClick={() => {window.location.href="/build/services-v2"}} title="">Doradztwo Podatkowe</Link></li>
                </ul>
                {/* <!-- /.one-half --> */}
                <ul className="one-half">
                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Home</Link></li>
                    <li><Link to="/contact-v1" onClick={() => {window.location.href="/contact-v1"}} title="">Kontakt</Link></li>
                </ul>
                {/* <!-- /.one-half --> */}
            </div>
        /* <!-- /.widget-services --> */
        );
    }
}

export default UsefulLink;