import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class TopFooter extends Component {
    render() {
        return (
            <div className="widget-infomation">
                <ul className="infomation-footer">
                    <li><i className="fa fa-envelope" aria-hidden="true"></i><Link to="#" title="">biuro@doradztwo-podatkowe.com</Link></li>
                    <li><i className="fa fa-phone" aria-hidden="true"></i><Link to="#" title="">+48 22 423-81-51</Link></li>
                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><Link to="#" title="">ul. Bukowińska 24 a lok. 67, 02-703 Warszawa</Link></li>
                </ul>
                {/* <!-- /.infomation-footer --> */}
            </div>
        /* <!-- /.widget-infomation --> */
        );
    }
}

export default TopFooter;